import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { DefaultPaletteType } from '@/constants/types/assets/DefaultPaletteType';
import { BorderDataType, UniqDataType } from '@/constants/types/palette/UnifiedVectorPaletteType';
import hexToRgba from '@/lib/convertors/hexToRgba';
import { fetcher } from '@/lib/tools/fetcher';
import { precision } from '@/lib/tools/precision';
import { PaletteModel } from '@/models/assets/PaletteModel';
import { FileModel } from '@/models/file/FileModel';
import type { MapModel } from '@/models/map/MapModel';
import ApiService from '@/services/api/ApiService';
import { UnifiedVectorDto } from '@/services/api/dto/gis/UnifiedVectorDto';
import AssetsDefaultPalettes from '@/services/assets/AssetsDefaultPalettes';
import LoggerService from '@/services/logger/LoggerService';
import { isNumber } from '@/utils/isNumber';
import { ElNotification } from 'element-plus';
import { Feature, FeatureCollection } from 'geojson';
import { reactive } from 'vue';

export class UnifiedVectorModel extends FileModel {
  get scope(): string {
    return this._scope;
  }

  get active(): boolean {
    return this._active;
  }

  get id(): number {
    return this._id;
  }

  get bbox(): [number, number, number, number] {
    return this._bbox;
  }

  get groupName(): string {
    return this._groupName;
  }

  get isProcessed(): boolean {
    return this._isProcessed;
  }

  get layerId(): number {
    return this._layerId;
  }

  get name(): string {
    return this._name;
  }

  get order(): number {
    return this._order;
  }

  set order(value: number) {
    this._order = value;
  }

  get preview(): string | null {
    return this._preview;
  }

  get type(): string {
    return this._type;
  }

  get updateDate(): Date {
    return this._updateDate;
  }

  get vector(): { id: number; filename: string | null; vectorType: number } | undefined {
    return this._vector;
  }

  private _id: number;

  private _bbox: [number, number, number, number];

  private _groupName: string;

  private _isProcessed: boolean;

  private _layerId: number;

  private _name: string;

  private _order: number;

  private _preview: string | null;

  private _type: string;

  private _updateDate: Date;

  private _vector: {
    id: number,
    filename: string | null,
    vectorType: number
  } | undefined = undefined

  private _active = false;

  private _geojson: FeatureCollection | undefined;

  public palette: PaletteModel | undefined = undefined;

  /** Все ключи найденные в векторе */
  public keys: string[] = reactive([]);

  /** Объект содержит уникальные значения из вектора для каждого ключа */
  public uniqueValues = reactive<Record<string, number[] | string[]>>({});

  /** Объект содержит все значения из вектора для каждого ключа */
  public values = reactive<Record<string, number[] | string[]>>({});

  /** Хранит ключи свойств, которые содержат числа */
  public numberKeys = reactive<string[]>([]);

  public precision = reactive<Record<string, number>>({});

  private readonly _scope: string;

  constructor(dto: UnifiedVectorDto) {
    super(dto.update_date);
    this._id = dto.id;
    this._bbox = dto.bbox;
    this._groupName = dto.group_name;
    this._isProcessed = dto.is_processed;
    this._layerId = dto.layer_id;
    this._name = dto.name;
    this._order = dto.order;
    this._preview = dto.preview || Math.random() < 0.5 ? null : 'https://cdn.onlinewebfonts.com/svg/img_98042.png';
    this._type = dto.type;
    this._updateDate = new Date(dto.update_date);
    this._scope = dto.scope;
    if (dto.vector) {
      this._vector = {
        id: dto.vector.id,
        filename: dto.vector.filename,
        vectorType: dto.vector.vector_type,
      };
    }
  }

  async setActive(value: boolean) {
    this._active = value;
    if (value) {
      await this.fetchGeojson();
    }
  }

  get featuresCollection(): FeatureCollection {
    const collection: FeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    };

    if (this._geojson?.features) {
      this._geojson.features.forEach((feature) => {
        let paletteColor = '#A5F3F3';
        if (this.palette?.property && feature.properties) {
          const value = feature.properties[this.palette.property] || null;
          if (this.palette.type === 'uniqValues') {
            const data = this.palette.uniqData as UniqDataType;
            const _palette = data.values.find((v) => v.value === value);
            if (_palette) {
              paletteColor = `rgba(${hexToRgba(_palette.color).join(', ')})`;
            }
          }
          if (this.palette.type === 'borderValues') {
            const data = this.palette.borderData as BorderDataType;
            const _palette = data.values.find((v, idx) => (value >= v.range.from && value < v.range.to) || (idx === data.values.length - 1 && v.range.to === value));
            if (_palette) {
              paletteColor = `rgba(${hexToRgba(_palette.color).join(', ')})`;
            }
          }
        }
        collection.features.push({
          type: 'Feature',
          geometry: feature.geometry,
          properties: {
            ...feature.properties,
            __paletteColor: paletteColor,
          },
        });
      });
    }
    return collection;
  }

  async fetchGeojson(force = false): Promise<void> {
    return fetcher(LoadingNamesEnum.VECTOR_GEOJSON, this.id, force, async () => {
      const { data } = await ApiService.gis.getVectorGeojson(this.id);
      if (data.geojson) {
        this._geojson = data.geojson;
        this.keys.splice(0, this.keys.length);
        Object.keys(this.uniqueValues).forEach((key) => {
          delete this.uniqueValues[key];
        });

        if (this._geojson.features) {
          this._geojson.features.forEach((feature: Feature) => {
            Object.keys(feature?.properties || {}).forEach((key: string) => {
              if (!key.startsWith('__') && !this.keys.includes(key)) {
                this.keys.push(key);
                this.uniqueValues[key] = [] as string[] | number[];
                this.values[key] = [] as string[] | number[];
              }

              if (Object.prototype.hasOwnProperty.call(this.uniqueValues, key)) {
                if (feature?.properties) {
                  const value: string | number = feature.properties[key] || '';
                  // @ts-ignore
                  if (!this.uniqueValues[key].includes(value)) {
                    // @ts-ignore
                    this.uniqueValues[key].push(value);
                  }
                  // @ts-ignore
                  this.values[key].push(value);
                }
              }
            });
          });
        }

        this.numberKeys.splice(0, this.numberKeys.length);
        this.keys.forEach((key) => {
          if (!this.uniqueValues[key].some((v: string | number) => !isNumber(v))) {
            this.numberKeys.push(key);
            this.precision[key] = 0;
            this.uniqueValues[key].forEach((v: string | number) => Number(v));
            this.precision[key] = Math.max(0, ...(this.uniqueValues[key] as number[]).map((v) => precision(v)));
          }
        });
      }
    });
  }

  async fetchPalette(paletteId: string): Promise<void> {
    return fetcher(LoadingNamesEnum.ASSETS_VECTOR_PALETTE, this.id, true, async () => {
      if (paletteId.startsWith('default:')) {
        const defaultPalette = AssetsDefaultPalettes.data.find((v: DefaultPaletteType) => v._id === paletteId.replace('default:', ''));
        if (defaultPalette) {
          const convertType = (type: string): 'uniqValues' | 'borderValues' => {
            switch (type) {
            case 'UNIQUE':
              return 'uniqValues';
            default:
              return 'borderValues';
            }
          };

          this.palette = new PaletteModel({
            numberKeys: this.numberKeys,
            keys: this.keys,
            uniqueValues: this.uniqueValues,
            values: this.values,
            precision: this.precision,
            dto: {
              _id: paletteId,
              property: defaultPalette._id,
              type: convertType(defaultPalette.type),
              data: PaletteModel.defaultPaletteToValues(defaultPalette, this.values[defaultPalette._id] || []),
            },
          });
        } else {
          LoggerService.error(`Default palette ${paletteId.replace('default:', '')} not found`);
        }
      }
      if (paletteId) {
        const { data } = await ApiService.assets.getPalette(paletteId.replace(':', '-'));
        this.palette = new PaletteModel({
          numberKeys: this.numberKeys,
          keys: this.keys,
          uniqueValues: this.uniqueValues,
          values: this.values,
          precision: this.precision,
          dto: data,
        });
      } else {
        this.palette = new PaletteModel({
          numberKeys: this.numberKeys,
          keys: this.keys,
          uniqueValues: this.uniqueValues,
          values: this.values,
          precision: this.precision,
          dto: undefined,
        });
      }
    });
  }

  async render(map: MapModel, treeKey: string): Promise<boolean> {
    await this.fetchGeojson();
    if (this.featuresCollection.features.length === 0) {
      ElNotification({
        title: 'Ошибка файла.',
        message: 'Файл вектора не имеет данных для отображения.',
        type: 'error',
        duration: 2000,
        position: 'bottom-right',
      });
      return false;
    }
    map.render(this, { treeKey });
    return true;
  }
}

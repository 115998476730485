<template src="./BaseMapEditor.pug" lang="pug"/>
<style src="./BaseMapEditor.scss" lang="scss"/>

<script lang="ts">
import {
  computed, defineComponent, nextTick, onMounted, onUnmounted, ref, watch,
} from 'vue';
import { BaseMapColors } from '@/assets/data/BaseMapColors';
import { CanvasSource, MapMouseEvent } from 'mapbox-gl';
import { BrushFilled, Grid, Pointer } from '@element-plus/icons-vue';
import { useBaseMapEditor } from '@/composables/baseMap/useBaseMapEditor';
import { canvasImageDataToZones } from '@/lib/tiff/canvasImageDataToZones';
import { useImageEditorHistory } from '@/composables/imageEditor/useImageEditorHistory';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { useCreateBaseMapModal } from '@/composables/baseMap/create/useCreateBaseMapModal';
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { MapCanvasModel } from '@/models/map/data/MapCanvasModel';
import { MapLayerCanvasModel } from '@/models/map/Layers/MapLayerCanvasModel';
import LoadingStatus from '@/services/loading/LoadingStatus';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import BaseMapEvents from '@/pages/task-map/create/base-map/BaseMapEvents';

export default defineComponent({
  name: 'BaseMapEditor',
  components: {
    Pointer,
    Grid,
    BrushFilled,
  },
  setup() {
    const {
      initHistory,
      addToHistory,
      undo,
      redo,
      reset,
      undoAvailable,
      redoAvailable,
      resetAvailable,
    } = useImageEditorHistory();
    const {
      canvasEditor,
      canvasEditorCtx,
      canvasSnap,
      canvasSnapCtx,
      canvasCursorSquare,
      canvasCursorSquareCtx,
      activeCandidate,
      generalizationSize,
      activeMode,
      activeGrid,
      snapLayer,
      cursorLayer,
      editorLayer,
    } = useCreateBaseMapModal();

    const {
      activeTool,
      eventsSubscription,
    } = useBaseMapEditor();

    const {
      fitField,
      activeField,
      mapModel,
      fitStruct,
      additionalMap,

    } = useMapContainers(MapContainerEnum.MAIN_MAP);

    const busy = ref(false);

    const width = ref();
    const height = ref();

    const brushing = ref({
      active: false,
      size: 10,
    });

    const activeZone = ref(1);

    // region helpers functions
    const getCursorPosition = (evt: MapMouseEvent) => {
      if (activeCandidate.value?.taskImage) {
        const pos = {
          x: 0,
          y: 0,
        };
        const cBbox = activeCandidate.value?.taskImage.bbox;
        const boxWidth = cBbox[2] - cBbox[0];
        const boxHeight = cBbox[3] - cBbox[1];
        const offsetX = ((evt.lngLat.lng - cBbox[0]) / boxWidth) * width.value;
        if (offsetX < 0 || offsetX > width.value) {
          return undefined;
        }
        pos.x = Math.round(offsetX);

        if (activeGrid.value) {
          pos.x = Math.floor(pos.x / brushing.value.size) * brushing.value.size + brushing.value.size / 2;
        }
        const offsetY = ((cBbox[3] - evt.lngLat.lat) / boxHeight) * height.value;
        if (offsetY < 0 || offsetY > height.value) {
          return undefined;
        }
        pos.y = Math.round(offsetY);

        if (activeGrid.value) {
          pos.y = Math.floor(pos.y / brushing.value.size) * brushing.value.size + brushing.value.size / 2;
        }
        return pos;
      }
      return undefined;
    };

    const getPixel = (id: Uint8ClampedArray, x: number, y: number, cw: number) => {
      const pos = ((y * cw) + x) * 4;
      return [id[pos], id[pos + 1], id[pos + 2], id[pos + 3]];
    };

    const setColor = (img: ImageData, i: number, color: number[]) => {
      img.data[(i * 4)] = color[0];
      img.data[(i * 4) + 1] = color[1];
      img.data[(i * 4) + 2] = color[2];
      img.data[(i * 4) + 3] = color[3];
    };
    // endregion

    const changeSnapVisibility = () => {
      if (snapLayer.value !== undefined) {
        additionalMap.value?.map?.setPaintProperty(
          snapLayer.value?.layerId || '',
          'raster-opacity',
          activeGrid.value ? 1 : 0,
        );
      }
    };
    watch(activeGrid, () => {
      changeSnapVisibility();
    });

    const drawSnap = () => {
      if (canvasSnap.value && canvasSnapCtx.value) {
        const bw = Math.floor(canvasSnap.value.width / brushing.value.size) * brushing.value.size;
        const bh = Math.floor(canvasSnap.value.height / brushing.value.size) * brushing.value.size;
        const p = 0;

        canvasSnapCtx.value.clearRect(0, 0, canvasSnap.value.width, canvasSnap.value.height);
        canvasSnapCtx.value.beginPath();

        for (let x = 0; x <= canvasSnap.value.width; x += brushing.value.size) {
          canvasSnapCtx.value.moveTo(0.5 + x + p, p);
          canvasSnapCtx.value.lineTo(0.5 + x + p, canvasSnap.value.height + p);
        }
        for (let y = 0; y <= bh; y += brushing.value.size) {
          canvasSnapCtx.value.moveTo(p, 0.5 + y + p);
          canvasSnapCtx.value.lineTo(bw + p, 0.5 + y + p);
        }
        canvasSnapCtx.value.strokeStyle = 'rgb(20,20,20)';
        canvasSnapCtx.value.lineWidth = 0.5;
        canvasSnapCtx.value.stroke();
        if (activeCandidate.value?.taskImage && additionalMap.value?.map) {
          const cBbox = activeCandidate.value.taskImage.bbox;
          const source = (additionalMap.value?.map.getSource(snapLayer.value?.sourceId || '') as CanvasSource);
          if (source) source.setCoordinates([[cBbox[0], cBbox[3]], [cBbox[2], cBbox[3]], [cBbox[2], cBbox[1]], [cBbox[0], cBbox[1]]]);

          const sourceCursor = (additionalMap.value?.map.getSource(cursorLayer.value?.sourceId || '') as CanvasSource);
          if (sourceCursor) sourceCursor.setCoordinates([[cBbox[0], cBbox[3]], [cBbox[2], cBbox[3]], [cBbox[2], cBbox[1]], [cBbox[0], cBbox[1]]]);
        }
      }
    };

    watch(brushing, (v) => {
      drawSnap();
      if (!v.active && activeGrid.value) {
        // activeGrid.value = false;
      }
    }, { deep: true });

    const initImageData = () => {
      if (activeCandidate.value?.taskImage?.zones
        && activeCandidate.value.taskImage.zones.length
        && canvasEditor.value
        && canvasSnap.value
        && canvasCursorSquare.value
      ) {
        width.value = activeCandidate.value.taskImage.scaledWidth;
        height.value = activeCandidate.value.taskImage.scaledHeight;
        activeZone.value = activeCandidate.value.taskImage.zones[0].value;
        canvasEditor.value.width = width.value;
        canvasEditor.value.height = height.value;
        canvasSnap.value.width = width.value;
        canvasSnap.value.height = height.value;
        canvasCursorSquare.value.width = width.value;
        canvasCursorSquare.value.height = height.value;
        drawSnap();
      }
    };

    const brushID = computed(() => {
      const id = new ImageData(brushing.value.size, brushing.value.size);
      for (let i = 0; i < brushing.value.size ** 2; i++) {
        setColor(id, i, BaseMapColors[activeZone.value]);
      }
      return id;
    });

    // region Brush tool
    const brushBeforePos = ref();

    const applyBrush = (x: number, y: number) => {
      if (canvasEditorCtx.value) {
        canvasEditorCtx.value.putImageData(brushID.value, x - brushing.value.size / 2, y - brushing.value.size / 2);
      }
    };

    const brush = (event: MapMouseEvent) => {
      const _pos = getCursorPosition(event);
      if (_pos) {
        applyBrush(_pos.x, _pos.y);
        if (brushBeforePos.value && canvasEditorCtx.value && !activeGrid.value) {
          const bx = brushBeforePos.value.x;
          const by = brushBeforePos.value.y;

          const dx = _pos.x - bx;
          const dy = _pos.y - by;

          const s = Math.max(Math.abs(dx), Math.abs(dy));
          const ix = dx === 0 ? 0 : Math.min(Math.abs(dx) / Math.abs(dy), 1);
          const iy = dy === 0 ? 0 : Math.min(Math.abs(dy) / Math.abs(dx), 1);

          for (let d = 1; d < s; d++) {
            const sdx = dx > 0 ? ix : -1 * ix;
            const sdy = dy > 0 ? iy : -1 * iy;
            const mx = Math.round(bx + d * sdx);
            const my = Math.round(by + d * sdy);
            applyBrush(mx, my);
          }
        }
        brushBeforePos.value = { x: _pos.x, y: _pos.y };
      }
    };
    // endregion

    // region Fill tool
    const fill = (evt: MapMouseEvent) => {
      if (!busy.value) {
        busy.value = true;
        setTimeout(() => {
          const cursorPos = getCursorPosition(evt);
          const cw = canvasEditor.value?.width || 0;
          const ch = canvasEditor.value?.height || 0;
          if (canvasEditorCtx.value && cursorPos) {
            const id = canvasEditorCtx.value.getImageData(0, 0, cw, ch);

            const initX = cursorPos.x;
            const initY = cursorPos.y;
            const pixel = getPixel(id.data, initX, initY, cw);
            const color = BaseMapColors[activeZone.value];
            if (pixel.join(',') === color.join(',')) {
              busy.value = false;
              return;
            }

            if (pixel.join(',') === BaseMapColors[0].join(',')) {
              busy.value = false;
              return;
            }

            const r = pixel[0];
            const g = pixel[1];
            const b = pixel[2];
            const stack = new Set();

            const getNearMatches = (x: number, y: number) => {
              [[x - 1, y], [x, y - 1], [x + 1, y], [x, y + 1]].forEach((coords) => {
                const _x = coords[0];
                const _y = coords[1];
                const hash = `${_x}:${_y}`;
                if (!stack.has(hash)) {
                  if (_x >= 0 && _x <= cw && _y >= 0 && _y <= ch && canvasEditorCtx.value) {
                    const _pixel = getPixel(id.data, _x, _y, cw);
                    if (_pixel[0] === r && _pixel[1] === g && _pixel[2] === b) {
                      stack.add(hash);
                    }
                  }
                }
              });
              stack.delete(`${x}:${y}`);

              const pos = ((y * cw) + x) * 4;
              id.data[pos] = color[0];
              id.data[pos + 1] = color[1];
              id.data[pos + 2] = color[2];
            };

            getNearMatches(initX, initY);

            let limitSteps = 1000000;

            do {
              // eslint-disable-next-line no-restricted-syntax
              for (const key of stack.values()) {
                // @ts-ignore
                const [_x, _y] = key.split(':');
                getNearMatches(Number(_x), Number(_y));
                limitSteps--;
              }
            } while (stack.size > 0 && limitSteps > 0);

            canvasEditorCtx.value?.putImageData(id, 0, 0);
          }
          if (canvasEditor.value && canvasEditorCtx.value) {
            addToHistory(canvasEditorCtx.value.getImageData(0, 0, canvasEditor.value.width, canvasEditor.value.height));
          }
        }, 20);
        busy.value = false;
      }
    };
    // endregion

    // Init History
    const initImage = () => {
      activeZone.value = activeCandidate.value?.taskImage?.zones[0].indexColor || 0;
      if (additionalMap.value && canvasEditorCtx.value && canvasEditor.value && activeCandidate.value?.taskImage?.imageDataZoned) {
        const buffer = (canvasEditorCtx.value.getImageData(0, 0, width.value || 0, height.value || 0)).data.buffer;

        const data = new Uint8ClampedArray(buffer);

        activeCandidate.value.taskImage.imageDataZoned.forEach((pxl: number, i: number) => {
          if (pxl > 0) {
            data[i * 4] = BaseMapColors[pxl][0];
            data[i * 4 + 1] = BaseMapColors[pxl][1];
            data[i * 4 + 2] = BaseMapColors[pxl][2];
            data[i * 4 + 3] = BaseMapColors[pxl][3];
          } else {
            data[i * 4] = BaseMapColors[pxl][0];
            data[i * 4 + 1] = BaseMapColors[pxl][1];
            data[i * 4 + 2] = BaseMapColors[pxl][2];
            data[i * 4 + 3] = BaseMapColors[pxl][3];
          }
        });

        canvasEditorCtx.value.putImageData(new ImageData(data, width.value, height.value), 0, 0);

        if (activeCandidate.value?.taskImage && additionalMap.value?.map) {
          const cBbox = activeCandidate.value.taskImage.bbox;
          const source = (additionalMap.value?.map.getSource(editorLayer.value?.sourceId || '') as CanvasSource);
          if (source) source.setCoordinates([[cBbox[0], cBbox[3]], [cBbox[2], cBbox[3]], [cBbox[2], cBbox[1]], [cBbox[0], cBbox[1]]]);
        }
        initHistory(canvasEditorCtx.value.getImageData(0, 0, canvasEditor.value.width, canvasEditor.value.height));
      }
    };
    // endregion

    const doUndo = () => {
      if (canvasEditorCtx.value && canvasEditor.value) {
        undo(canvasEditorCtx.value, canvasEditor.value);
      }
    };

    const doRedo = () => {
      if (canvasEditorCtx.value) {
        redo(canvasEditorCtx.value);
      }
    };

    const doReset = () => {
      if (canvasEditorCtx.value) {
        reset(canvasEditorCtx.value);
      }
    };

    let previousSquareCoords: { x: number, y: number } = { x: 0, y: 0 };

    const dragSquare = (event: MapMouseEvent) => {
      const cursorPos = getCursorPosition(event);
      if (canvasCursorSquareCtx.value && canvasCursorSquare.value && cursorPos === undefined) {
        canvasCursorSquareCtx.value.clearRect(0, 0, canvasCursorSquare.value.width, canvasCursorSquare.value.height);
        return;
      }
      if (canvasCursorSquareCtx.value && canvasCursorSquare.value && cursorPos) {
        if (previousSquareCoords) {
          const { x, y } = previousSquareCoords;
          canvasCursorSquareCtx.value.clearRect(x - 1, y - 1, brushing.value.size + 2, brushing.value.size + 2);
        }

        const squareX = cursorPos.x - brushing.value.size / 2;
        const squareY = cursorPos.y - brushing.value.size / 2;

        canvasCursorSquareCtx.value.strokeStyle = 'black';
        canvasCursorSquareCtx.value.strokeRect(squareX, squareY, brushing.value.size, brushing.value.size);

        previousSquareCoords = { x: squareX, y: squareY };
      }
    };

    onMounted(() => {
      BaseMapEvents.onZoneChanged(initImageData);
      activeTool.value = 'pointer';

      setTimeout(async () => {
        if (additionalMap.value?.map) {
          additionalMap.value?.map.on('mousedown', (evt: MapMouseEvent) => {
            if (activeTool.value === 'brush' && !busy.value) {
              brushing.value.active = true;
              brush(evt);
              evt.preventDefault();
            }
          });

          additionalMap.value?.map.on('mousemove', (evt: MapMouseEvent) => {
            EventBus.$emit(EventsEnum.MapMouseMove, evt);
            if (activeTool.value === 'brush') {
              dragSquare(evt);
              if (brushing.value.active && !busy.value) {
                brush(evt);
              }
            }
          });

          additionalMap.value?.map.on('click', (evt: MapMouseEvent) => {
            if (activeTool.value === 'fill' && !busy.value) {
              fill(evt);
            }
          });

          additionalMap.value?.map.on('mouseup', (evt: MapMouseEvent) => {
            if (brushing.value.active) {
              brushing.value.active = false;
              brushBeforePos.value = undefined;
              if (canvasEditor.value && canvasEditorCtx.value) {
                addToHistory(canvasEditorCtx.value.getImageData(0, 0, canvasEditor.value.width, canvasEditor.value.height));
              }
            }
          });
          additionalMap.value?.map.on('mouseover', (e) => {
            setTimeout(() => {
              mapModel.value?.cursor?.setVisibility(false);
            });
          });
          additionalMap.value?.map.on('mouseout', (e) => {
            EventBus.$emit(EventsEnum.MapMouseMove, { lngLat: { lng: 0, lat: 0 } });
            setTimeout(() => {
              mapModel.value?.cursor?.setVisibility(true);
            });
          });

          BaseMapEvents.onEditSave(() => {
            if (activeCandidate.value?.taskImage && canvasEditorCtx.value) {
              const buffer = (canvasEditorCtx.value.getImageData(0, 0, width.value, height.value)).data;
              activeCandidate.value.taskImage.imageDataZoned = canvasImageDataToZones(buffer);
              BaseMapEvents.emitEditSaved();
            }
          });

          eventsSubscription(additionalMap.value?.map, 'editor');
        }
      }, 500);

      EventBus.$on(EventsEnum.OnKeyPressed, (evt: KeyboardEvent) => {
        if (activeMode.value === 'edit') {
          if (evt.code.startsWith('Digit') && activeCandidate.value?.taskImage?.zones) {
            const key = Number(evt.key) - 1;
            if (activeCandidate.value.taskImage.zones.length >= key + 1) {
              activeZone.value = activeCandidate.value.taskImage.zones[key].indexColor;
            }
          }
          if (evt.code === 'KeyF') {
            activeTool.value = 'fill';
          }
          if (evt.code === 'KeyC') {
            activeTool.value = 'pointer';
          }
          if (evt.code === 'KeyB') {
            activeTool.value = 'brush';
          }
          if (evt.code === 'KeyZ' && evt.ctrlKey && !evt.shiftKey) {
            doUndo();
          }
          if (evt.code === 'KeyZ' && evt.ctrlKey && evt.shiftKey) {
            doRedo();
          }
        }
      });
    });

    const createCanvas = () => {
      const canvasTypes = [

        {
          id: 'CreateBaseMapCanvas-editor', refs: { canvas: canvasEditor, ctx: canvasEditorCtx, layer: editorLayer }, map: additionalMap,
        },
        {
          id: 'CreateBaseMapCanvas-snap', refs: { canvas: canvasSnap, ctx: canvasSnapCtx, layer: snapLayer }, map: additionalMap,
        },
        {
          id: 'CreateBaseMapCanvas-cursorSquare', refs: { canvas: canvasCursorSquare, ctx: canvasCursorSquareCtx, layer: cursorLayer }, map: additionalMap,
        },
      ];

      canvasTypes.forEach(({
        id, refs, map,
      }) => {
        const canvas = document.createElement('canvas');
        canvas.id = id;
        canvas.style.display = 'none';
        document.body.querySelector('.MapContainer-additionalMap')?.appendChild(canvas);

        const elementCanvas = new MapCanvasModel(id, [
          [Math.random(), Math.random()],
          [Math.random(), Math.random()],
          [Math.random(), Math.random()],
          [Math.random(), Math.random()],
        ], true);

        const layer = map.value?.render(elementCanvas) as MapLayerCanvasModel;
        refs.canvas.value = elementCanvas.canvas;
        refs.ctx.value = elementCanvas.ctx;
        refs.layer.value = layer;
      });
    };

    onMounted(async () => {
      fitField(activeField.value);
      BaseMapEvents.onCandidateModeChange(() => {
        if (additionalMap.value?.map && activeCandidate.value?.taskImage) {
          initImageData();
          const cBbox = activeCandidate.value.taskImage.bbox;
          const center: [number, number] = [cBbox[0] + (cBbox[2] - cBbox[0]) / 2, cBbox[1] + (cBbox[3] - cBbox[1]) / 2];
          additionalMap.value?.map.setCenter(center);
        }
      });
      await LoadingStatus.awaitLoad(LoadingNamesEnum.MAP_CONTAINER, `${MapContainerEnum.MAIN_MAP}-additional`);
      createCanvas();
      initImageData();
      initImage();
      mapModel.value.map.resize();
      additionalMap.value?.map?.resize();
      additionalMap.value?.map?.setPaintProperty(
        snapLayer.value?.layerId || '',
        'raster-opacity',
        0,
      );
      const center: {lng: number, lat: number} = mapModel.value.map.getCenter();
      const zoom = mapModel.value.map.getZoom();
      additionalMap.value?.map.setCenter([center.lng, center.lat]);
      additionalMap.value?.map.setZoom(zoom);
    });

    watch(activeTool, (a) => {
      if (a === 'brush') {
        additionalMap.value.map.getCanvas().style.cursor = 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAcxJREFUSEvV1EuoTXEUx/HPjaIYuAwUpSS6ZCaGBpgoBvKo61LkMSAhBgYeeQwNCANlwIBcj8ktSVFGBsy8klBEGShiYCCPVq2j3b53t7ezuwP/OnXOae/fb/1/67tWj1E+PaOs778wGIN+rMMbHMK3TjJtbhDC63EQcwpRn8WutgZzcRGLUuhZVr8St7CijcFqXMIEfMRu3MRjzMMGXO7WYAcigoj2KuL35+zBFbzAfPzsxmAbzqf4UcTnN6YgIpqKAYTR39O0yctwG2OxF6dSoXOTIOgBFherj2eaGEzGE0zDaewpFHg8KfqKBXhVHtwmBnHl4DziiEpvpMg+nMQvrMLQSFuhziCICcEviO+B5oHEM+gJ0+24ULVyqgxmYgs2YXoh96W4m2I/EI0PZCvPSAZbcQbjC29FtudwBJPwHhtxv25Zlg2W5yTG/4M5QCdKqyB6sjNjq9MfRtFzxBo4ltWGwBpcx4ckqNPkWvEyprPxEp8wA99T4RrWIpA83Ei1YtCW4B4eJSXjkvHYlmHWh3dtDBbiYWYbgxU7pTdR3FxHSxNMY6dEPMXzFPtx518r7zxfpihMZmEi3uJ1t8JVBm31hr1ftypaG/4BfnZXGfkpANcAAAAASUVORK5CYII=) 2 20, auto';
      } else if (a === 'fill') {
        additionalMap.value.map.getCanvas().style.cursor = 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAASZJREFUSEvV1T0uRUEYxvHflShFRWcHVCISHQo2ILEFdkDPBoQdKNUSiU4htAo7IEq9kFfOSU5O5pyZuXILU00yM89/3ueZj4kZt8mM9ZUAjnCDj2k2kwMc4xIv2J0GkgMs4R5reMUO3moqyQFC60+QWkAAqyrJAZYbi1abHALQ9osyGQP0rdnGV20mQ4CU+HsTblUmKcCYeHuAiiF9wCIeCn3u55PMpA84xVnFxcpC+oATnNdcpM7c5G0fs6iG04ofYA4X7eJUyN2ySyCt+Aoe+X1At/AUi4eOaSmka8st9psd3WFvDBBjOUjf808sNIDox4nM/gdDkFSgYclGA4j+ZgkgVcnQ37COa8zjEM+lgJgX5cbn840rhAVFLfeaFomMTfr/gB/avFEZes7meQAAAABJRU5ErkJggg==) 16 16, auto';
      } else {
        additionalMap.value.map.getCanvas().style.cursor = '';
      }
    });

    // region resize watcher
    onUnmounted(() => {
      additionalMap.value.map.getCanvas().style.cursor = '';
      additionalMap.value.removeMap();
      additionalMap.value = undefined;
      nextTick(() => {
        mapModel.value.map.resize();
      });
    });
    // endregion

    return {
      activeCandidate,
      activeTool,
      width,
      height,
      busy,
      activeZone,
      undoAvailable,
      redoAvailable,
      resetAvailable,
      activeGrid,
      brushing,
      doUndo,
      doRedo,
      doReset,
      BaseMapColors,
    };
  },
});
</script>

import { ExperimentsCollection } from '@/collection/ExperimentsCollection';
import { FieldsCollection } from '@/collection/FieldsCollection';
import { PoiCollection } from '@/collection/PoiCollection';
import { TaskCollection } from '@/collection/TaskCollection';
import { MapLayerPropertyEnum } from '@/constants/enums/MapLayerPropertyEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MapInputType } from '@/constants/types/map/MapInputType';
import { MapLayerConfigType } from '@/constants/types/map/MapLayerConfigType';
import { FieldAverageIndexModel } from '@/models/field/FieldAverageIndexModel';
import { FieldIndexHistoryModel } from '@/models/field/FieldIndexHistoryModel';
import { FieldIndexMonitoringModel } from '@/models/field/FieldIndexMonitoringModel';
import { FieldModel } from '@/models/field/FieldModel';
import { FieldNirModel } from '@/models/field/FieldNirModel';
import { FieldTaskMapBaseModel } from '@/models/field/FieldTaskMapBaseModel';
import { FieldTaskMapFactModel } from '@/models/field/FieldTaskMapFactModel';
import { FieldTaskMapHarvestModel } from '@/models/field/FieldTaskMapHarvestModel';
import { FieldTaskMapWorkModel } from '@/models/field/FieldTaskMapWorkModel';
import { MapAreaModel } from '@/models/map/data/MapAreaModel';
import { MapCanvasModel } from '@/models/map/data/MapCanvasModel';
import { MapRulerModel } from '@/models/map/data/MapRulerModel';
import { MapLayerCanvasModel } from '@/models/map/Layers/MapLayerCanvasModel';
import { MapLayerDrawerModel } from '@/models/map/Layers/MapLayerDrawerModel';
import { MapLayerExperimentsModel } from '@/models/map/Layers/MapLayerExperimentsModel';
import { MapLayerFieldLoadingModel } from '@/models/map/Layers/MapLayerFieldLoadingModel';
import { MapLayerFieldsModel } from '@/models/map/Layers/MapLayerFieldsModel';
import { MapLayerIndexModel } from '@/models/map/Layers/MapLayerIndexModel';
import { MapLayerNirModel } from '@/models/map/Layers/MapLayerNirModel';
import { MapLayerPoiModel } from '@/models/map/Layers/MapLayerPoiModel';
import { MapLayerRasterModel } from '@/models/map/Layers/MapLayerRasterModel';
import { MapLayerRulerModel } from '@/models/map/Layers/MapLayerRulerModel';
import { MapLayerTaskMapBaseModel } from '@/models/map/Layers/MapLayerTaskMapBaseModel';
import { MapLayerTaskMapCollectionModel } from '@/models/map/Layers/MapLayerTaskMapCollectionModel';
import { MapLayerTaskMapContourModel } from '@/models/map/Layers/MapLayerTaskMapContourModel';
import { MapLayerTaskMapFactModel } from '@/models/map/Layers/MapLayerTaskMapFactModel';
import { MapLayerTaskMapHarvestModel } from '@/models/map/Layers/MapLayerTaskMapHarvestModel';
import { MapLayerTaskMapWorkModel } from '@/models/map/Layers/MapLayerTaskMapWorkModel';
import { MapLayerUnifiedVectorModel } from '@/models/map/Layers/MapLayerUnifiedVectorModel';
import { MapLayerVectorModel } from '@/models/map/Layers/MapLayerVectorModel';
import { MonitoringTaskMapContourModel } from '@/models/monitoring/MonitoringTaskMapContourModel';
import { RasterModel } from '@/models/RasterModel';
import { UnifiedVectorModel } from '@/models/vector/UnifiedVectorModel';
import { VectorModel } from '@/models/VectorModel';

export default [

  {
    type: MapLayerTypeEnum.FIELDS,
    input: (dataModel: MapInputType) => dataModel instanceof FieldsCollection,
    model: MapLayerFieldsModel,
    properties: [MapLayerPropertyEnum.SINGLE, MapLayerPropertyEnum.SORTABLE, MapLayerPropertyEnum.SETTINGS],
  },
  {
    type: MapLayerTypeEnum.TASK_MAP_BASE,
    input: (dataModel: MapInputType) => dataModel instanceof FieldTaskMapBaseModel,
    model: MapLayerTaskMapBaseModel,
    properties: [MapLayerPropertyEnum.SINGLE, MapLayerPropertyEnum.SORTABLE, MapLayerPropertyEnum.SETTINGS],
  },
  {
    type: MapLayerTypeEnum.TASK_MAP_WORK,
    input: (dataModel: MapInputType) => dataModel instanceof FieldTaskMapWorkModel,
    model: MapLayerTaskMapWorkModel,
    properties: [MapLayerPropertyEnum.SINGLE, MapLayerPropertyEnum.SORTABLE, MapLayerPropertyEnum.SETTINGS],
  },
  {
    type: MapLayerTypeEnum.TASK_MAP_HARVEST,
    input: (dataModel: MapInputType) => dataModel instanceof FieldTaskMapHarvestModel,
    model: MapLayerTaskMapHarvestModel,
    properties: [MapLayerPropertyEnum.SINGLE, MapLayerPropertyEnum.SORTABLE, MapLayerPropertyEnum.SETTINGS],
  },
  {
    type: MapLayerTypeEnum.TASK_MAP_FACT,
    input: (dataModel: MapInputType) => dataModel instanceof FieldTaskMapFactModel,
    model: MapLayerTaskMapFactModel,
    properties: [MapLayerPropertyEnum.SINGLE, MapLayerPropertyEnum.SORTABLE, MapLayerPropertyEnum.SETTINGS],
  },
  {
    type: MapLayerTypeEnum.TASK_MAP_COLLECTION,
    input: (dataModel: MapInputType) => dataModel instanceof TaskCollection,
    model: MapLayerTaskMapCollectionModel,
    properties: [MapLayerPropertyEnum.SINGLE, MapLayerPropertyEnum.SORTABLE, MapLayerPropertyEnum.SETTINGS],
  },
  {
    type: MapLayerTypeEnum.FIELD_LOADING,
    input: (dataModel: MapInputType) => dataModel instanceof FieldModel,
    model: MapLayerFieldLoadingModel,
    properties: [MapLayerPropertyEnum.SINGLE, MapLayerPropertyEnum.SETTINGS],
  },
  {
    type: MapLayerTypeEnum.VECTOR_FILE,
    input: (dataModel: MapInputType) => dataModel instanceof VectorModel,
    model: MapLayerVectorModel,
    properties: [MapLayerPropertyEnum.SINGLE, MapLayerPropertyEnum.SORTABLE, MapLayerPropertyEnum.SETTINGS],
  },
  {
    type: MapLayerTypeEnum.NIR_FILE,
    input: (dataModel: MapInputType) => dataModel instanceof FieldNirModel,
    model: MapLayerNirModel,
    properties: [MapLayerPropertyEnum.SINGLE, MapLayerPropertyEnum.SORTABLE, MapLayerPropertyEnum.SETTINGS],
  },
  {
    type: MapLayerTypeEnum.POI,
    input: (dataModel: MapInputType) => dataModel instanceof PoiCollection,
    model: MapLayerPoiModel,
    properties: [MapLayerPropertyEnum.SINGLE, MapLayerPropertyEnum.SETTINGS],
  },
  {
    type: MapLayerTypeEnum.TASK_MAP_EXPERIMENT,
    input: (dataModel: MapInputType) => dataModel instanceof ExperimentsCollection,
    model: MapLayerExperimentsModel,
    properties: [MapLayerPropertyEnum.SINGLE, MapLayerPropertyEnum.SETTINGS],
  },
  {
    type: MapLayerTypeEnum.RASTER_FILE,
    input: (dataModel: MapInputType) => dataModel instanceof RasterModel,
    model: MapLayerRasterModel,
    properties: [MapLayerPropertyEnum.MULTIPLE, MapLayerPropertyEnum.SORTABLE, MapLayerPropertyEnum.SETTINGS],
  },
  {
    type: MapLayerTypeEnum.HISTORY_INDEX,
    input: (dataModel: MapInputType) => dataModel instanceof FieldIndexHistoryModel,
    model: MapLayerIndexModel,
    properties: [MapLayerPropertyEnum.MULTIPLE, MapLayerPropertyEnum.SORTABLE, MapLayerPropertyEnum.SETTINGS],
  },
  {
    type: MapLayerTypeEnum.AVERAGE_INDEX,
    input: (dataModel: MapInputType) => dataModel instanceof FieldAverageIndexModel,
    model: MapLayerIndexModel,
    properties: [MapLayerPropertyEnum.MULTIPLE, MapLayerPropertyEnum.SORTABLE, MapLayerPropertyEnum.SETTINGS],
  },
  {
    type: MapLayerTypeEnum.MONITORING_INDEX,
    input: (dataModel: MapInputType) => dataModel instanceof FieldIndexMonitoringModel,
    model: MapLayerIndexModel,
    properties: [MapLayerPropertyEnum.MULTIPLE, MapLayerPropertyEnum.SORTABLE],
  },
  {
    type: MapLayerTypeEnum.AREA,
    input: (dataModel: MapInputType) => dataModel instanceof MapAreaModel,
    model: MapLayerDrawerModel,
    properties: [MapLayerPropertyEnum.MULTIPLE, MapLayerPropertyEnum.SETTINGS],
  },
  {
    type: MapLayerTypeEnum.RULER,
    input: (dataModel: MapInputType) => dataModel instanceof MapRulerModel,
    model: MapLayerRulerModel,
    properties: [MapLayerPropertyEnum.MULTIPLE, MapLayerPropertyEnum.SETTINGS],
  },
  {
    type: MapLayerTypeEnum.CANVAS,
    input: (dataModel: MapInputType) => dataModel instanceof MapCanvasModel,
    model: MapLayerCanvasModel,
    properties: [MapLayerPropertyEnum.MULTIPLE],
  },
  {
    type: MapLayerTypeEnum.UNFIED_VECTOR,
    input: (dataModel: MapInputType) => dataModel instanceof UnifiedVectorModel,
    model: MapLayerUnifiedVectorModel,
    properties: [MapLayerPropertyEnum.MULTIPLE],
  },
  {
    type: MapLayerTypeEnum.TASK_MAP_CONTOUR,
    input: (dataModel: MapInputType) => dataModel instanceof MonitoringTaskMapContourModel,
    model: MapLayerTaskMapContourModel,
    properties: [MapLayerPropertyEnum.SINGLE],
  },

] as MapLayerConfigType[];

<template src="./FieldDrawBlock.pug" lang="pug"/>
<style src="./FieldDrawBlock.scss" lang="scss"/>

<script lang="ts">
import RightPanel from '@/components/shared/RightPanel/RightPanel.vue';
import { useFormatter } from '@/composables/useFormatter';
import { useMap } from '@/composables/useMap';
import { useMapContainers } from '@/composables/useMapContainers';
import { FIELD_MAX_AREA_SIZE } from '@/constants/constants/fields';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { RuleFieldName } from '@/constants/rules/RuleFieldName';
import StructList from '@/modules/struct/StructList';
import ApiService from '@/services/api/ApiService';
import EventBus from '@/services/eventBus/EventBus';
import LoggerService from '@/services/logger/LoggerService';
import { area } from '@turf/turf';
import { ElNotification, FormInstance, FormRules } from 'element-plus';
import {
  defineComponent, onMounted, onUnmounted, reactive, ref, toRefs, watch,
} from 'vue';
import UiDrawer from '@/components/ui/Drawer/UiDrawer.vue';
import { MapAreaModel } from '@/models/map/data/MapAreaModel';
import { MapLayerDrawerModel } from '@/models/map/Layers/MapLayerDrawerModel';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';

export default defineComponent({
  name: 'FieldDrawBlock',
  components: {
    UiDrawer,
    RightPanel,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:open'],
  setup(props, { emit }) {
    const { rulerActive, blockRulerTool } = useMap();
    const { formatEwkt } = useFormatter();
    const { fields, mapModel } = useMapContainers(MapContainerEnum.MAIN_MAP);
    const openDrawer = ref(false);

    const form = ref({
      name: '',
      farmunit: 0,
      name_rus: '',
      descr: '',
      sq_acc: 0,
    });

    const fieldDrawRef = ref<FormInstance>();
    const checkSq = ref<number>(0);
    const disabled = ref(true);

    const areaLayer = ref<MapLayerDrawerModel>();

    const _areaModel = reactive(new MapAreaModel());

    const areaModel = toRefs(_areaModel);
    const lengthPolygon = ref(0);

    watch(() => props.open, (a) => {
      openDrawer.value = a;
      if (a) {
        _areaModel.clearCoordinates();
        areaLayer.value = mapModel.value.render(_areaModel as MapAreaModel) as MapLayerDrawerModel;
        areaLayer.value.setActiveMode('create');
      } else {
        mapModel.value.removeLayer(MapLayerTypeEnum.AREA);
        areaLayer.value.setActiveMode('none');
        areaLayer.value.offEventListener();
        _areaModel.clearCoordinates();
        areaLayer.value = undefined;
      }
    });

    watch(() => areaModel, () => {
      lengthPolygon.value = (areaLayer.value?.cordsDrawer.length) || 0;
      checkSq.value = Math.round(area(_areaModel.polygon) / 100) / 100;
      lengthPolygon.value < 3 ? (disabled.value = true) : (disabled.value = false);
    }, { deep: true });

    const validateGeom = (rule: any, value: any, callback: any) => {
      if ('coordinates' in _areaModel.polygon.geometry) {
        if ((_areaModel.polygon?.geometry.coordinates[0] as number[][]).length === 0) {
          callback(new Error('Определите контур поля на карте'));
        } else {
          callback();
        }
      }
    };

    const validateName = (rule: any, value: any, callback: any) => {
      if (fields.value.some((f) => f.name === form.value.name)) {
        callback(new Error('Данное имя не уникально'));
      } else {
        callback();
      }
    };

    const rules = reactive<FormRules>({
      name: [
        ...RuleFieldName,
        {
          validator: validateName,
          trigger: 'blur',
        },
      ],
      name_rus: [
        {
          min: 0,
          max: 256,
          message: 'Длина полного наименования поля не должна превышать 256 символов',
          trigger: ['blur', 'change'],
        },
      ],
      geom: [
        {
          required: true,
          validator: validateGeom,
          trigger: 'blur',
        },
      ],
    });

    const closeWindow = () => {
      emit('update:open', false);
    };

    const save = () => {
      const formData = new FormData();
      disabled.value = true;
      Object.keys(form.value).forEach((key) => {
        // @ts-ignore
        formData.append(key, form.value[key]);
      });
      if ('coordinates' in _areaModel.polygon.geometry) {
        formData.append('geom', formatEwkt(_areaModel.polygon.geometry.coordinates[0] as number[][]));
        ApiService.gis.postFieldAdd(formData)
          .then((resp) => {
            closeWindow();
            // const fields = new FieldModel(resp.data.geojson);
            // FieldsList.fields.value.push(fields);
            EventBus.$emit(EventsEnum.MapFeaturesUpdated);

            disabled.value = false;
          })

          .catch((response) => {
            LoggerService.error(response.message);
            disabled.value = false;
          });
      }
    };

    const onSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      formEl.validate((valid) => {
        if (valid) {
          if (form.value.sq_acc <= FIELD_MAX_AREA_SIZE && checkSq.value <= FIELD_MAX_AREA_SIZE) {
            save();
          } else {
            ElNotification({
              title: 'Детали',
              message: `Поле должно быть меньше ${FIELD_MAX_AREA_SIZE} га`,
              type: 'warning',
              position: 'bottom-right',
            });
          }
        }
      });
    };

    const isNumber = (evt: KeyboardEvent): void => {
      const charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      }
    };

    onMounted(() => {
      form.value.farmunit = StructList.activeStruct.value?.id || 0;
      rulerActive.value = false;
      blockRulerTool.value = true;
      form.value.sq_acc = 0;
    });

    onUnmounted(() => {
      blockRulerTool.value = false;
      emit('update:open', false);
    });
    return {
      FIELD_MAX_AREA_SIZE,
      form,
      rules,
      fieldDrawRef,
      areaModel,
      closeWindow,
      onSubmit,
      isNumber,
      disabled,
      openDrawer,
      lengthPolygon,
    };
  },
});
</script>

<template lang="pug" src="./BaseMapEdit.pug"/>
<style lang="scss" src="./BaseMapEdit.scss"/>

<script lang="ts">
import { BaseMapColors } from '@/assets/data/BaseMapColors';
import { RasterValue } from '@/assets/data/RasterValue';
import RightPanel from '@/components/shared/RightPanel/RightPanel.vue';
import ProgressCard from '@/components/ui/ProgressCard/ProgressCard.vue';
import { useCreateBaseMapModal } from '@/composables/baseMap/create/useCreateBaseMapModal';
import { useBaseMapEditor } from '@/composables/baseMap/useBaseMapEditor';
import { useForceUpdate } from '@/composables/useForceUpdate';
import { useMapContainers } from '@/composables/useMapContainers';
import { useUser } from '@/composables/useUser';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { formatRuDate } from '@/utils/formatRuDate';
import {
  CaretRight, Lock, Minus, Plus, Unlock,
} from '@element-plus/icons-vue';
import { mdiLock, mdiLockOpenVariant } from '@mdi/js';
import { ElMessage, ElNotification } from 'element-plus';
import {
  computed, defineComponent, onMounted, onUnmounted, ref,
} from 'vue';
import BaseMapEvents from '@/pages/task-map/create/base-map/BaseMapEvents';

export default defineComponent({
  name: 'BaseMapEdit',
  components: {
    ProgressCard,
    RightPanel,
    Plus,
    Minus,
    Lock,
    Unlock,
    CaretRight,
  },

  setup() {
    const { user } = useUser();
    const {
      candidates,
      activeCandidate,
      activeMode,
      setActiveMode,
      generalizationSize,
    } = useCreateBaseMapModal();

    const {
      activeField,
      compareMode,
      fitField,
      addAdditionalMap,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);

    const { focused } = useBaseMapEditor();

    const { updateKey, forceUpdate } = useForceUpdate();

    const getZoneByIndex = (value: number) => activeCandidate.value?.taskImage?.getZoneByValue(value);

    const getZoneColor = (value: number | undefined) => (value ? BaseMapColors[value] : BaseMapColors[0]);
    const getGroupZoneColor = (value: number | undefined) => (value ? BaseMapColors[getZoneByIndex(value)?.indexColor || 0] : BaseMapColors[0]);

    const settingsCopy = ref<{
      zones: string | null,
      sourceZones: string | null
    }>({
      zones: null,
      sourceZones: null,
    });

    // eslint-disable-next-line camelcase
    const form = ref<{value: number, zone: string}[]>([]);
    const dialogVisible = ref(false);

    const disableForm = (index: number) => {
      let check = true;
      (activeCandidate.value?.taskImage?.zones || []).forEach((z) => {
        if (index === z.value) {
          check = false;
        }
      });
      return check;
    };

    /**
     * Обновление шаблона карты на карте.
     */
    const rePaintBaseMap = () => {
      BaseMapEvents.emitZoneChanged();
    };

    onMounted(() => {
      setTimeout(() => {
        rePaintBaseMap();
      }, 200);
    });

    /**
     * Является ли индекс последним в зоне
     * @param index
     */
    const isZoneLast = (index: number): boolean => {
      const zone = getZoneByIndex(index);
      return zone?.value === index;
    };

    /**
     * Является ли индекс первым в зоне
     * @param index
     */
    const isZoneFirst = (index: number): boolean => {
      const zone = getZoneByIndex(index);
      return zone?.min === index;
    };

    /**
     * Является ли зона из одного индекса
     * @param index
     */
    const isZoneAlone = (index: number): boolean => {
      const zone = getZoneByIndex(index);
      return zone?.min === zone?.value;
    };

    const isLocked = computed(() => (index: number): boolean => {
      const zone = getZoneByIndex(index);
      return !!zone && zone.value !== index;
    });

    const hoverElement = ref({ position: '', index: 0 });

    /**
     * Обработка события наведения мыши на строку в форме редактирования зон.
     * @param v
     */
    const onMouseEnterElement = (v: number) => {
      (activeCandidate.value?.taskImage?.zones || []).forEach((z, index) => {
        if (v <= z.value && v >= z.min) {
          if (v === z.min && v === z.value) {
            hoverElement.value = { position: 'solo', index };
          } else if (v === z.min) {
            hoverElement.value = { position: 'min', index };
          } else if (v === z.value) {
            hoverElement.value = { position: 'max', index };
          } else {
            hoverElement.value = { position: 'ok', index };
          }
        }
      });
    };

    const computedZoneProc = computed(() => (index: number) => getZoneByIndex(index)?.proc || 0);

    /**
     * Проверяет на какой цвет активен и делает выделение
     * @param color
     */
    const isColorActive = (index: number): boolean => (activeCandidate.value?.taskImage?.zones || []).some((zone) => zone.indexColor === index);

    /**
     * Установить активный цвет зоны.
     * @param color - hex
     * @param index
     */
    const setZoneColor = (index: number): void => {
      if (!activeCandidate.value?.isEdited) {
        const zone = getZoneByIndex(index);
        if (zone) zone.indexColor = index;
          activeCandidate.value?.taskImage?.computeImageDataZoned();
          rePaintBaseMap();
      } else {
        ElNotification({
          message: 'Настройка зон недоступна после редактирования.',
          type: 'error',
          position: 'bottom-right',
        });
      }
    };

    const isEvenRow = (index: number) => {
      let check = false;
      (activeCandidate.value?.taskImage?.zones || []).forEach((z, i) => {
        if (index <= z.value && index >= z.min && i % 2 === 0) {
          check = true;
        }
      });
      return !check;
    };

    const syncZoneProc = (zoneValue: number) => {
      const asfZone = activeCandidate.value?.taskImage?.sourceZones.find((v) => v.value === zoneValue);
      const zone = getZoneByIndex(zoneValue);
      if (zone && asfZone) {
        activeCandidate.value?.taskImage?.sourceZones.forEach((v) => {
          if (v.value >= zone.min && v.value < zone.value) {
            v.proc = asfZone.proc;
            v.proc_seed = asfZone.proc_seed;
          }
        });
        zone.proc = asfZone.proc;
        zone.procSeed = asfZone.proc_seed;
      }
      forceUpdate();
    };

    const computedZonesData = computed(() => {
      const data: Record<number, any> = {};
      (activeCandidate.value?.taskImage?.zones || []).forEach((z) => {
        data[z.indexColor] = {
          indexColor: z.indexColor,
          pixels: 0,
          progress: 0,
          area: 0,
        };
      });

      if (activeCandidate.value?.taskImage) {
        let pixelSum = 0;
        Array.from(activeCandidate.value?.taskImage.imageDataZoned).forEach((v) => {
          if (v > 0 && data[v]) {
            pixelSum++;
            data[v].pixels += 1;
          }
        });

        Object.keys(data).forEach((k) => {
          data[Number(k)].progress = Math.round((data[Number(k)].pixels * 10000) / pixelSum) / 100;
          data[Number(k)].area = (data[Number(k)].pixels * (activeField.value?.sq || 0)) / pixelSum;
        });
      }
      return data;
    });

    const onFocus = (zone: number) => {
      focused.value = getZoneByIndex(zone)?.indexColor || 0;
      BaseMapEvents.emitFocusChange();
    };

    const onBlur = (zone: number) => {
      focused.value = 0;
      BaseMapEvents.emitFocusChange();
      syncZoneProc(zone);
    };

    const regroup = (zone: number) => {
      if (activeCandidate.value?.isEdited) {
        ElNotification({
          message: 'Настройка зон недоступна после редактирования.',
          type: 'error',
          position: 'bottom-right',
        });
      } else {
        if (activeCandidate.value?.taskImage?.zones) {
          if (isLocked.value(zone)) {
            const z = getZoneByIndex(zone);
            if (z) {
              const nz = [
                {
                  min: z.min,
                  value: zone,
                  proc: z.proc,
                  procSeed: z.procSeed,
                  indexColor: z.indexColor <= zone ? z.indexColor : zone,
                },
                {
                  min: zone + 1,
                  value: z.value,
                  proc: z.proc,
                  procSeed: z.procSeed,
                  indexColor: z.indexColor > zone ? z.indexColor : z.value,
                },
              ];
              activeCandidate.value.taskImage.zones = [
                ...activeCandidate.value.taskImage.zones.filter((v) => v.value !== z.value),
                ...nz,
              ].sort((a, b) => a.value - b.value);
            }
          } else {
            const z1 = getZoneByIndex(zone);
            const z2 = getZoneByIndex(zone + 1);
            if (z1 && z2) {
              const nz = [
                {
                  min: z1.min,
                  value: z2.value,
                  proc: z2.proc,
                  procSeed: z2.procSeed,
                  indexColor: z2.indexColor,
                },
              ];
              activeCandidate.value.taskImage.zones = [
                ...activeCandidate.value.taskImage.zones.filter((v) => ![z1.value, z2.value].includes(v.value)),
                ...nz,
              ].sort((a, b) => a.value - b.value);
              syncZoneProc(z2.value);
            }
          }
        }
        rePaintBaseMap();
      }
    };

    const changeCandidate = () => {
      activeCandidate.value?.reset();
      setActiveMode('selector');
      activeCandidate.value = undefined;
    };

    const copySettings = () => {
      if (!focused.value && activeCandidate.value?.taskImage?.zones && activeCandidate.value?.taskImage?.sourceZones) {
        settingsCopy.value.zones = JSON.stringify(activeCandidate.value.taskImage.zones);
        settingsCopy.value.sourceZones = JSON.stringify(activeCandidate.value.taskImage.sourceZones);
        ElMessage({
          message: 'Настройки формы успешно скопированы в буффер.',
          type: 'success',
        });
      }
    };

    const isPasteSettingsAvailable = computed(() => !activeCandidate.value?.isEdited
      && activeCandidate.value?.taskImage
      && settingsCopy.value.zones
      && settingsCopy.value.sourceZones);

    const pasteSettings = () => {
      if (!focused.value && isPasteSettingsAvailable.value && activeCandidate.value?.taskImage) {
        activeCandidate.value.taskImage.pasteZones(settingsCopy.value.zones);
        activeCandidate.value.taskImage.pasteSourceZones(settingsCopy.value.sourceZones);
        rePaintBaseMap();

        ElMessage({
          message: 'Настройки формы успешно обновлены.',
          type: 'info',
        });
      }
    };

    const handleEdit = () => {
      addAdditionalMap().then(() => {
        setActiveMode('edit');
        setTimeout(
          () => {
            fitField(activeField.value);
          }, 500,
        );
      });
    };
    const generalization = () => {
      dialogVisible.value = false;
      activeCandidate.value?.taskImage?.generalization(generalizationSize.value);
    };

    onUnmounted(() => {
      setActiveMode('selector');
    });
    return {
      RasterValue,
      BaseMapColors,
      user,
      activeCandidate,
      activeMode,
      changeCandidate,
      isZoneFirst,
      isZoneLast,
      isZoneAlone,
      onMouseEnterElement,
      hoverElement,
      isColorActive,
      setZoneColor,
      isEvenRow,
      form,
      disableForm,
      getZoneColor,
      getGroupZoneColor,
      candidates,
      syncZoneProc,
      computedZonesData,
      onFocus,
      formatRuDate,
      onBlur,
      isLocked,
      mdiLock,
      mdiLockOpenVariant,
      regroup,
      updateKey,
      computedZoneProc,
      copySettings,
      pasteSettings,
      focused,
      isPasteSettingsAvailable,
      handleEdit,
      dialogVisible,
      generalizationSize,
      generalization,
    };
  },
});
</script>
